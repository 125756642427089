/* eslint-disable no-undef */
// eslint-disable-next-line import/prefer-default-export
export const maskPhoneInput = () => {
  const phoneDdiInputs = document.querySelectorAll('input.phone_ddi');
  const phoneInputs = document.querySelectorAll('input.phone');
  const phoneDdiText = document.querySelectorAll('.phone_ddi_format');

  function definePhoneDdiMask(element) {
    Inputmask({ regex: '\\+\\d+', placeholder: ' ' }).mask(element);
  }

  function definePhoneMask(phoneInput, ddi = '') {
    if (ddi.replace(/[^\d]/g, '') === '55') Inputmask('(99) 99999-9999', { placeholder: ' ' }).mask(phoneInput);
    else Inputmask({ regex: '\\d+', placeholder: ' ' }).mask(phoneInput);
  }

  function handleDdiChange(targetOrEvent) {
    const target = targetOrEvent instanceof Event ? targetOrEvent.currentTarget : targetOrEvent;
    const phoneInputId = target.dataset.maskForPhone;

    if (phoneInputId) {
      const phoneInput = document.querySelector(`#${phoneInputId}`);
      if (phoneInput) definePhoneMask(phoneInput, target.value);
    }
  }

  phoneInputs.forEach((phoneInput) => {
    definePhoneMask(phoneInput);
  });

  phoneDdiInputs.forEach((ddiInput) => {
    definePhoneDdiMask(ddiInput);

    if (ddiInput.value.replace(/[^\d]/g, '') === '55') handleDdiChange(ddiInput);

    ddiInput.addEventListener('change', handleDdiChange);
  });

  phoneDdiText.forEach((ddi) => {
    if (ddi.innerText.replace(/[^\d]/g, '') === '55') {
      definePhoneMask(ddi.parentElement.querySelector('.phone_format'), '55');
    }

    definePhoneDdiMask(ddi);
  });
};
