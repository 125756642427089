export default () => {};
export const handlePanelModal = () => {
  const modals = document.querySelectorAll('.modal-orange');

  modals.forEach((modal) => {
    modal.querySelector('.modal-close').addEventListener('click', (e) => {
      e.preventDefault();

      modal.style.display = 'none';
      sessionStorage.setItem('bamboleioUserPanelModal', true);
    });

    const hideModal = sessionStorage.getItem('bamboleioUserPanelModal');

    if (hideModal !== 'true') modal.style.display = null;
    else modal.style.display = 'none';
  });
};
