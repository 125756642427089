export const deleteSelected = () => {
  const btnDelete = document.querySelector("#deleteBtn");

  const sendSelected = (idsArray) => {
    const path = window.location.pathname;
    const selectedIds = {ids: idsArray};

    fetch(`${path}/delete_all`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedIds),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      });
  };

  const getSelected = () => {
    const selectedCheckboxes = document.querySelectorAll(".selectable:checked");
    const idsArray = []
    selectedCheckboxes.forEach((checkbox) => {
      idsArray.push(checkbox.value);
    })
    sendSelected(idsArray);
  }

  if (btnDelete) {
    btnDelete.addEventListener('click', () => {
      if (confirm("Você realmente deseja deletar os registros selecionados?")) {
        getSelected();
      }
    })
  }
}
