export const resendEmailConfirmation = () => {
  const sendEmail = document.querySelector("#email_confirmation");

  if(sendEmail){
    sendEmail.addEventListener('click',(event) => {
      event.preventDefault();
      let result = confirm("O email de confirmação será reenviado, você tem certeza?")
      var formData = new FormData();
      formData.append("id", event.target.dataset.user);

      if(result){
        fetch("/admin/users/resend_confirmation_email",{
          method: "POST",
          body: formData
        })
        .then((resp) => {
          alert("Email enviado com sucesso");
        })
        .catch((error) => {
          alert("Ocorreu um erro");
        });
      }
    })
  }
};
