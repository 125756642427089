export default () => {};

export const handleDiscountFields = () => {
  const couponTypeOfDiscount = document.querySelector('#coupon_type_of_discount');
  const couponDiscount = document.querySelector('#coupon_discount');
  const couponDuration = document.querySelectorAll('.coupon_duration, [for="coupon_duration"]');
  const couponDurationField = document.querySelector('#coupon_duration');
  const couponDurationInMonths = document.querySelectorAll('.coupon_duration_in_months, [for="coupon_duration_in_months"]');

  function showDuration() {
    const show = couponTypeOfDiscount.value !== 'no_discount';

    if (show) {
      couponDuration.forEach((e) => { e.style.display = null; });
    } else {
      couponDuration.forEach((e) => { e.style.display = 'none'; });
    }

    if (couponDuration.value === 'repeating' && show) {
      couponDurationInMonths.forEach((e) => { e.style.display = null; });
    } else {
      couponDurationInMonths.forEach((e) => { e.style.display = 'none'; });
    }
  }

  function defineDiscountMask(input) {
    Inputmask.remove(couponDiscount);
    const amount = input ? couponTypeOfDiscount.value === 'amount' : couponTypeOfDiscount.innerText.trim() === 'Quantia';

    if (input) {
      const value = couponDiscount.value.replace(/[^\d,.]/g, '').replace(',', '.');
      if (amount) {
        couponDiscount.value = Number(value).toFixed(2).replace('.', ',');
      } else {
        couponDiscount.value = parseInt(value);
      }
    }

    if (amount) {
      Inputmask({ regex: 'R\\$ \\d+,\\d{2}', placeholder: '' }).mask(couponDiscount);
    } else {
      Inputmask({ regex: '\\d{1,3}%', placeholder: '' }).mask(couponDiscount);
    }
  }

  function showDiscount() {
    if (couponTypeOfDiscount.value === 'no_discount') {
      couponDiscount.parentElement.style.display = 'none';
    } else {
      defineDiscountMask(true);
      couponDiscount.parentElement.style.display = null;
    }

    showDuration();
  }

  if (couponTypeOfDiscount) {
    if (couponTypeOfDiscount.nodeName.toUpperCase() === 'SELECT') {
      showDiscount();
      couponTypeOfDiscount.addEventListener('change', showDiscount);
    } else {
      defineDiscountMask(false);
    }
  }

  if (couponDuration.length > 0) {
    couponDurationField.addEventListener('change', showDuration);
  }
};

export const handlePartnerField = () => {
  function changeSelectOptions(options, select) {
    Array.from(select.options).forEach((e) => e.remove());
    select.appendChild(document.createElement('option'));

    options.forEach((school) => {
      const option = document.createElement('option');
      option.text = school.name;
      option.value = school.id;
      select.appendChild(option);
    });
  }

  const selectedList = document.querySelector('.coupon_partner_ids .select2-selection__rendered');
  const partnerField = document.querySelector('#coupon_partner_ids');
  const schoolField = document.querySelector('#coupon_school_id');
  const classroomField = document.querySelector('#coupon_classroom_id');

  function updateClassroomField(id) {
    fetch(`/admin/coupons/get_classrooms?school_id=${id}`)
      .then((response) => response.json())
      .then((data) => changeSelectOptions(data, classroomField));
  }

  schoolField.addEventListener('change', (e) => updateClassroomField(e.target.value));

  const observer = new MutationObserver(() => {
    let partnerIds = Array.from(partnerField.selectedOptions).map((opt) => `partner_ids[]=${opt.value}`);
    partnerIds = partnerIds.join('&');
    fetch(`/admin/coupons/get_schools?${partnerIds}`)
      .then((response) => response.json())
      .then((data) => {
        changeSelectOptions(data, schoolField);
        changeSelectOptions([], classroomField);
      });
  });
  observer.observe(selectedList, { attributes: true, childList: true, characterData: true });
};
