export default () => {
  function setDocumentMask(targetOrEvent) {
    const element = targetOrEvent instanceof Event ? targetOrEvent.currentTarget : targetOrEvent;
    let doc = element.nodeName.toUpperCase() === 'INPUT' ? element.value : element.innerText;
    doc = doc.replace(/\D/g, '');

    if (doc.length < 12) {
      Inputmask("999.999.999-999", { placeholder: " " }).mask(element);
    } else {
      Inputmask("99.999.999/9999-99", { placeholder: " " }).mask(element);
    }
  }

  const documentsSelectors = [
    "#partner_document",
    "#publisher_document",
    "#publisher_account_doc",
    "#school_document",
    "#user_document",
    ".document-format",
  ];
  const allDocFields = document.querySelectorAll(documentsSelectors.join(', '));

  allDocFields.forEach((field) => {
    field.setAttribute('type', 'tel');
    setDocumentMask(field);

    field.addEventListener('keyup', setDocumentMask);
  });

  const brazilianField = document.querySelector('#user_brazilian');
  if (brazilianField) {
    const checkBrazilian = () => {
      const isBrazilian = brazilianField.value === 'true';
      const documentFormGroup = document.querySelector('.user_document');

      if (isBrazilian) documentFormGroup.style.display = null;
      else documentFormGroup.style.display = 'none';
    };
    checkBrazilian();

    brazilianField.addEventListener('change', checkBrazilian);
  }
};
