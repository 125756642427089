export const nationalitySelected = () => {
  const nacionalityInput = document.querySelector("#user_brazilian");
  const cpfCnpjInput = document.querySelector("#user_document");

  if(nacionalityInput && cpfCnpjInput){
    nacionalityInput.addEventListener("change", (event) => {
      const select_value = event.target.value;

      switch(select_value){
        case "false":
          cpfCnpjInput.style.display = "none";
          break;
        case "true":
        case "":
          cpfCnpjInput.style.display = "flex";
          break;
      }
    });
  }
}
