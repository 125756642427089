export default () => {
  function createSpin() {
    const spin = document.createElement("div");
    spin.classList.add("spin-20px");
    for (let i = 1; i <= 8; i += 1) {
      const line = document.createElement("div");
      line.classList.add("spin-20px-line");
      spin.appendChild(line);
    }
    return spin;
  }

  function createIconSuccess() {
    const icon = document.createElement("i");
    icon.classList.add("fas", "fa-check-circle");
    return icon;
  }

  function createIconError(form, errorMsg = null) {
    const icon = document.createElement("i");
    icon.classList.add("bamb", "sync");
    const btn = document.createElement("button");
    btn.classList.add("btn-resend");
    btn.appendChild(icon);
    btn.title = "Tentar novamente";
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      submitForm(form);
    });
    const msg = document.createElement("span");
    msg.innerHTML = errorMsg || "Ops, algo deu errado";
    const div = document.createElement("div");
    div.style.display = "flex";
    div.style.alignItems = "center";
    div.appendChild(btn);
    div.appendChild(msg);
    return div;
  }

  function submitForm(form) {
    const icon = form.querySelector(".icon");
    icon.innerHTML = "";
    icon.appendChild(createSpin());

    function RecordInvalid(msg) {
      this.message = msg;
      this.name = "RecordInvalid";
    }

    fetch(form.action, {
      method: "POST",
      body: new URLSearchParams(new FormData(form)),
    })
      .then((resp) => (resp.status !== 204 ? resp.json() : resp))
      .then((resp) => {
        icon.innerHTML = "";
        if (!resp.ok) {
          if (resp.error) throw new RecordInvalid(resp.error);
          else throw new Error("Ops, algo deu errado");
        }

        icon.appendChild(createIconSuccess());
      })
      .catch((error) => {
        let iconError = createIconError(form);
        if (error instanceof RecordInvalid) iconError = createIconError(form, error.message);

        icon.innerHTML = "";
        icon.appendChild(iconError);
      });
  }

  const settingForms = document.querySelectorAll(".setting-form");

  settingForms.forEach((form) => {
    const inputs = form.querySelectorAll("input");
    inputs.forEach((input) => {
      input.addEventListener("change", () => {
        submitForm(form);
      });
    });
  });
};
