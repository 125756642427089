export const changeSwitchText = () => {
  const switchToggle = document.getElementById("customSwitch1");
  const statusText = document.querySelector("#header-table label span");

  if (switchToggle) {
    switchToggle.addEventListener("change", () => {
      if (statusText.innerText == "Ativo") {
        statusText.innerText = "Inativo";
      } else {
        statusText.innerText = "Ativo";
      }
    })
  }
};
