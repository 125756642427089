export const handleMenu = () => {
  const sidebar = document.querySelector('.client-panel .sidebar');

  if (sidebar) {
    const menu = sidebar.querySelector('.menu');
    const title = menu.querySelector('.title');

    title.addEventListener('click', () => {
      menu.classList.toggle('active');
    });
  }
};
