export const confirmationCheckbox = () => {
  const registrationPlan = document.querySelector(".new-registration-form");

  if (registrationPlan) {
    registrationPlan.addEventListener("submit", (event) => {
      event.preventDefault();
      const confirmationTerms = document.getElementById("confirmation-terms").checked;

      if (confirmationTerms) {
        registrationPlan.submit();
      } else {
        const legalTermsLabel = document.querySelector('label[for="confirmation-terms"]');
        legalTermsLabel.style.color = "red";
        legalTermsLabel.scrollIntoView();
      }
    });
  }
};

