// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import $ from "jquery";
import select2 from "select2";
import "select2/dist/css/select2.css";
import "jquery-mask-plugin/dist/jquery.mask.min";
//import sortable from "html5sortable/dist/html5sortable.cjs";
import Sortable from "sortablejs";
import "slick-carousel";
import Inputmask from "inputmask";
import { carrouselList } from "./carrousel_list";
import { changeSwitchText } from "./change_switch_text";
import { confirmationCheckbox } from "./confirmation_checkbox";
import { deleteSelected } from "./delete_selected";
import { exportSelected } from "./export_selected";
import { indexSelects } from "./index_selects";
import { maskPhoneInput } from "./mask_phone_input";
import { resendEmailConfirmation } from "./resend_email_confirmation";
import { sidebarCollapse } from "./sidebar";
import settingsIndex from './settings/index';
import booksIndex from './books/index';
import usersIndex from './users/index';
import handlePin from "./handle_pin";
import { nationalitySelected } from "./nationality_selected";
import handleDocument from "./handle_document";
import handleDiscountFields from "./coupons";
import clientsIndex from "./clients";
import plansIndex from "./plans";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery-mask-plugin");
require("trix");
require("@rails/actiontext");

document.addEventListener("turbolinks:load", () => {
  $(".multiselect-bamboleio").each(function () {
    if ($(this)) $(this).select2();
  });
  $(".js-example-tags").select2({
    tags: true,
    tokenSeparators: [",", " "],
  });
  var slides = $(".slick-active");
  // var slides = document.getElementsByClassName('slick-active');
  $("#card-plan").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    centerMode: slides.lenght > 2,
    responsive: [
      {
        breakpoint: 1620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
    // autoplay: true,
    // autoplay: true,
    // autoplaySpeed: 1000,
    // variableWidth: true
  });
});

document.addEventListener("turbolinks:load", () => {
  carrouselList();
  changeSwitchText();
  confirmationCheckbox();
  deleteSelected();
  exportSelected();
  indexSelects();
  maskPhoneInput();
  sidebarCollapse();
  settingsIndex();
  booksIndex();
  clientsIndex();
  usersIndex();
  plansIndex();
  handlePin();
  nationalitySelected();
  resendEmailConfirmation();
  handleDocument();
  handleDiscountFields();
});
