export const handleSelectIcon = () => {
  const profileForm = document.querySelector('.new-profile form, .edit-profile form');

  if (profileForm) {
    const iconInputs = profileForm.querySelectorAll('.icons input');
    iconInputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        const iconImages = profileForm.querySelectorAll('.icons img');
        iconImages.forEach((img) => img.classList.remove('active'));
        const iconImage = e.currentTarget.parentElement.querySelector('img');
        iconImage.classList.add('active');
        const profileIcon = document.querySelector('.profile-icon img');
        profileIcon.src = iconImage.src;
      });
    });
  }
};
