export const exportSelected = () => {
  const btnExport = document.querySelector("#exportBtn");

  const sendSelected = (idsArray, accept) => {
    const path = window.location.pathname;
    const selectedIds = { ids: idsArray };

    fetch(`${path}/export_all`, {
      method: "POST",
      headers: {
        Accept: "text/csv",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedIds),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const date = new Date();
        const day = date.getDate();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}-${month}-${year}`;

        const controller = path.replace(/\/admin\//, "");

        a.download = `${controller}_${dateString}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const getSelected = () => {
    const selectedCheckboxes = document.querySelectorAll(".selectable:checked");
    const idsArray = [];
    selectedCheckboxes.forEach((checkbox) => {
      idsArray.push(checkbox.value);
    });
    sendSelected(idsArray);
  };

  if (btnExport) {
    btnExport.addEventListener("click", () => {
      if (
        confirm("Você realmente deseja exportar os registros selecionados?")
      ) {
        getSelected();
      }
    });
  }
};
