export default () => {
  const pinFields = Array.from(document.querySelectorAll('.pin-fields input'));

  function backField(parentElement) {
    const previousField = parentElement.previousElementSibling.querySelector('input');
    if (previousField) previousField.focus();
  }

  pinFields.forEach((elem) => {
    elem.setAttribute('type', 'number');
    elem.addEventListener('keyup', (e) => {
      const { target } = e;
      const { parentElement } = target;

      if (e.key === 'Backspace' && target.value === '') {
        backField(parentElement);
      }
    });

    elem.addEventListener('input', (e) => {
      const { target } = e;
      const { parentElement } = target;
      const isChar = e.data && e.data.match(/[^\d]+/);

      if (isChar) {
        target.value = '';
      } else if (e.inputType === 'deleteContentBackward') {
        backField(parentElement);
      } else {
        target.value = e.data;

        const inputHidden = parentElement.parentElement.nextElementSibling.querySelector('input');
        inputHidden.value = pinFields.map((input) => input.value).join('');

        const nextField = parentElement.nextElementSibling;

        if (nextField) {
          nextField.querySelector('input').focus();
        } else {
          const passwordField = parentElement.parentElement
            .parentElement
            .nextElementSibling
            .querySelector('input');

          passwordField.focus();
        }
      }
    });
  });
};
