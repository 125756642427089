export const removeOptions = () => {
  const removeFileGroups = Array.from(document.querySelectorAll('.remove-file'));
  const inputs = removeFileGroups.map((elem) => elem.querySelector('input'));

  inputs.forEach((elem) => elem.addEventListener('change', (e) => {
    const checkBox = e.currentTarget;
    const inputFile = checkBox.parentElement.parentElement.querySelector('input[type="file"]');
    const fileName = checkBox.parentElement.parentElement.querySelector('.file-name');

    fileName.style.display = '';
    if (checkBox.checked && inputFile.value) {
      inputFile.value = '';
      fileName.textContent = '';
    } else if (checkBox.checked) {
      fileName.style.display = 'none';
    }
  }));
};
