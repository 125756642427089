export const indexSelects = () => {
  const selectAll = document.getElementById("selectAll");
  const selectable = document.querySelectorAll('.selectable');
  const btnCreate = document.querySelector(".btn-create");
  const btnDelete = document.querySelector("#deleteBtn");
  const btnExport = document.querySelector("#exportBtn");

  if (selectAll || selectable) {
    const checkSelectAll = () => {
      selectable.forEach((select) => {
        select.checked = selectAll.checked;
      })
    };

    const checkSelectable = () => {
      const selectedCheckboxes = document.querySelectorAll(".selectable:checked");
      if (selectedCheckboxes.length == selectable.length) {
        selectAll.checked = true;
      } else {
        selectAll.checked = false;
      }
    }

    const changeDeleteCreate = () => {
      const selectedCheckboxes = document.querySelectorAll(".selectable:checked");
      if (selectedCheckboxes.length > 0) {
        btnDelete.classList.remove("d-none");
        // btnExport.classList.remove("d-none");
        btnCreate.classList.add("d-none");
      } else {
        btnDelete.classList.add("d-none");
        // btnExport.classList.add("d-none");
        btnCreate.classList.remove("d-none");
      }
    }

    if (selectAll) {
      selectAll.addEventListener("change", () => {
        checkSelectAll();
        changeDeleteCreate();
      });
    }

    if (selectable) {
      selectable.forEach((checkbox) => {
        checkbox.addEventListener("change", () => {
          checkSelectable();
          changeDeleteCreate();
        });
      })
    }
  }

}
