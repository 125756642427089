export default () => {};

export const handleAppStoreOptions = () => {
  const appStoreOption = document.querySelector('#plan_app_store');

  if (appStoreOption) {
    const productId = document.querySelector('#plan_product_id');
    const planCouponIds = Array.from(document.querySelectorAll('.plan_coupon_ids select, .plan_coupon_ids input'));
    const planSku = document.querySelector('#plan_sku');
    const planDefault = document.querySelector('#plan_default');
    const planTrialPeriodDays = document.querySelector('#plan_trial_period_days');
    const planDisplayOrder = document.querySelector('#plan_display_order');
    const planInterval = document.querySelector('#plan_interval');
    const planRecurring = document.querySelector('#plan_recurring');
    const planIntervalCount = document.querySelector('#plan_interval_count');
    const planPrice = document.querySelector('#plan_price');

    const appStoreFields = [productId];
    const stripeFields = [
      planCouponIds[0],
      planCouponIds[1],
      planSku,
      planDefault,
      planTrialPeriodDays,
      planDisplayOrder,
      planInterval,
      planRecurring,
      planIntervalCount,
      planPrice,
    ];

    appStoreOption.addEventListener('change', () => {
      appStoreFields.forEach((elem) => { elem.disabled = !appStoreOption.checked; });
      stripeFields.forEach((elem) => { elem.disabled = appStoreOption.checked; });
    });

    appStoreFields.forEach((elem) => { elem.disabled = !appStoreOption.checked; });
    stripeFields.forEach((elem) => { elem.disabled = appStoreOption.checked; });
  }
};
