export const carrouselList = () => {
  var el = document.querySelector(".carousel-list");

  if (el) {
    var sortable = Sortable.create(el, {
      group: "localStorage-example",
      animation: 150,
      store: {
        set: function (sortable) {
          var order = sortable.toArray();
          var formData = new FormData();
          formData.append("order", order);
          formData.append(
            "authenticity_token",
            document.querySelector('[name="authenticity_token"]').value
          );
          fetch("/admin/carousels/update_order", {
            method: "PUT",
            body: formData,
          })
            .then((resp) => {
              console.log(resp);
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
    });
  }
};
